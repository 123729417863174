import React from 'react';
import * as Styled from './FindExpert.styles';
import expert from 'assets/images/expert.svg';
import { FindExpertSubject, FindExpertSubscribes } from './FindExpert.subject';

const FindExpert = ({ openInNewWindow }) => {
  const clickBcgExperts = () => {
    FindExpertSubject.next({ type: FindExpertSubscribes.BCG });
    const url = "https://link.bcg.com/people/default.aspx#/experts";
    if(openInNewWindow) {
      window.open(url);
    } else {
      window.location.href = url;
    }
  }

  const clickExternalExperts = () => {
    FindExpertSubject.next({ type: FindExpertSubscribes.EXTERNAL });
    const url = "https://externalexperts.bcg.com";
    if(openInNewWindow) {
      window.open(url);
    } else {
      window.location.href = url;
    }
  }

  return (
    <Styled.Wrapper>
      <Styled.Expert src={expert} alt="Expert" />
      <h3>Find an Expert</h3>
      <div>Find an expert within BCG or utilize our external network of SMEs.</div>
      <Styled.TheButton inverted onClick={clickBcgExperts} padTop>BCG Experts</Styled.TheButton>
      <Styled.TheButton inverted onClick={clickExternalExperts}>External Expert Networks</Styled.TheButton>
    </Styled.Wrapper>
  )
}

export default FindExpert;
