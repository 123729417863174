import { SearchFilterInterface, IConfigData, CountType } from "../components/notification-view/notification.interface";
import { getNotificationsData } from './api-helper'

class NotificationServiceController {
  public notificationData: SearchFilterInterface;
  private audienceEmail: string;
  private publisher: string;
  private limit: number;
  private offset: number = 0;
  private envConfig: IConfigData;
  private countType: CountType;

  async load(category: Array<string>, accessToken) {
      this.offset = 0;
      this.notificationData = await getNotificationsData(this.envConfig, accessToken, this.audienceEmail, category, this.publisher, this.limit, this.offset, this.countType);
      return this.notificationData;
  }

  getData(category: Array<string>, accessToken) {
      return this.load(category, accessToken);
  }
  
  saveUserInfo(audienceEmail: string, envConfig: IConfigData, publisher: string, countType: CountType) {
    this.audienceEmail = audienceEmail;
    this.envConfig = envConfig;
    this.publisher = publisher;
    this.limit = 100;
    this.countType = countType;
  }
}

export const NotificationService = new NotificationServiceController();