export interface SearchFilterInterface {
    notifications: Array<NotificationInterface>;
    categoryFilters: Array<CategoryFiltersInterface> ;
    totalCount: number;
}

export interface CategoryFiltersInterface {
  field: string;
  count: number;
}

export interface UpdateNotificationInterface {
  _id: string;
  fieldsToUpdate: UpdateNotificationFieldsInterface;
}

export interface UpdateNotificationFieldsInterface {
  category: string;
  domainId: string;
  title: string;
  body: string;
  channels: Array<string>;
  state: string;
}

export interface NotificationInterface {
  _id: string;
  category: string;
  domainId: string;
  title: string;
  isBase64: boolean;
  body: string;
  plainTextMessage: string;
  status: StatusInterface;
  channels: Array<string>;
  publishedAt: string;
  state: string;
  priority: string;
  publisher: string;
  expiryTime: string ;
  expireAfter: number ;
  action: string ;
  actionURI: string ;
  audience: string ;
}

export interface StatusInterface {
  color: string;
  description: string;
}

export interface AudienceInterface {
  audienceType: string;
  audienceId: string;
  audienceList: Array<string>;
  properties: PropertiesInterface;
}

export interface PropertiesInterface {
  senderEmail: string;
  targetEmails: TargetEmailInterface;
}

export interface TargetEmailInterface {
  toList: Array<string>;
  ccList: string;
  bccList: string;
}

export interface IToggleBtnData {
  keyId: string;
  isEnabled: boolean;
}

export interface IConfigData {
  apiHost: string;
  apiKey: string;
  psid: string;
}

export interface SubscriptionChannelsProps {
  pushEnabled?: SubscriptionChannelProps;
  emailEnabled?: SubscriptionChannelProps;
  inboxEnabled?: SubscriptionChannelProps;
}

export interface SubscriptionChannelProps {
  displayName: string;
}

export enum CountType {
  NEW = 'new',
  UNREAD = 'unread'
}